import { AqaraTempHumiPresSensor } from '../../api/API'

export enum Status {
  IDLE = 'idle',
  PENDING = 'pending',
  FAILED = 'failed'
}

export interface SensorListState {
  devices: AqaraTempHumiPresSensor[]
  status: Status
}
