/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type DevicesResponse = {
  __typename: "DevicesResponse",
  devices:  Array<DeviceState >,
};

export type DeviceState = MoesMS104ZSwitch | AqaraTempHumiPresSensor


export type MoesMS104ZSwitch = {
  __typename: "MoesMS104ZSwitch",
  deviceId: string,
  elapsed?: number | null,
  last_seen?: number | null,
  linkquality?: number | null,
  name: string,
  device?: SwitchDeviceInfo | null,
  state: MoesMS104ZSwitchState,
  power_on_behavior?: MoesMS104ZSwitchPowerOnBehavior | null,
};

export type IDevice = {
  __typename: "IDevice",
  deviceId: string,
  elapsed?: number | null,
  last_seen?: number | null,
  linkquality?: number | null,
  name: string,
};

export type AqaraTempHumiPresSensor = {
  __typename: "AqaraTempHumiPresSensor",
  deviceId: string,
  elapsed?: number | null,
  last_seen?: number | null,
  linkquality?: number | null,
  name: string,
  device?: SensorDeviceInfo | null,
  battery?: number | null,
  humidity?: number | null,
  pressure?: number | null,
  temperature?: number | null,
  voltage?: number | null,
  power_outage_count?: number | null,
};

export type SensorDeviceInfo = {
  __typename: "SensorDeviceInfo",
  applicationVersion?: number | null,
  friendlyName?: string | null,
  hardwareVersion?: number | null,
  ieeeAddr?: string | null,
  manufacturerID?: number | null,
  manufacturerName?: string | null,
  model?: string | null,
  networkAddress?: number | null,
  powerSource?: string | null,
  stackVersion?: number | null,
  type?: string | null,
  zclVersion?: number | null,
  dateCode?: string | null,
  softwareBuildID?: string | null,
};

export type IDeviceInfo = {
  __typename: "IDeviceInfo",
  applicationVersion?: number | null,
  friendlyName?: string | null,
  hardwareVersion?: number | null,
  ieeeAddr?: string | null,
  manufacturerID?: number | null,
  manufacturerName?: string | null,
  model?: string | null,
  networkAddress?: number | null,
  powerSource?: string | null,
  stackVersion?: number | null,
  type?: string | null,
  zclVersion?: number | null,
};

export type SwitchDeviceInfo = {
  __typename: "SwitchDeviceInfo",
  applicationVersion?: number | null,
  friendlyName?: string | null,
  hardwareVersion?: number | null,
  ieeeAddr?: string | null,
  manufacturerID?: number | null,
  manufacturerName?: string | null,
  model?: string | null,
  networkAddress?: number | null,
  powerSource?: string | null,
  stackVersion?: number | null,
  type?: string | null,
  zclVersion?: number | null,
};

export enum MoesMS104ZSwitchState {
  ON = "ON",
  OFF = "OFF",
  TOGGLE = "TOGGLE",
}


export enum MoesMS104ZSwitchPowerOnBehavior {
  ON = "ON",
  OFF = "OFF",
  PREVIOUS = "PREVIOUS",
}


export type UpdateSwitchStateInput = {
  name: string,
  state: MoesMS104ZSwitchState,
};

export enum MeasurementType {
  TEMPERATURE = "TEMPERATURE",
  HUMIDITY = "HUMIDITY",
  PRESSURE = "PRESSURE",
  BATTERY = "BATTERY",
  VOLTAGE = "VOLTAGE",
  LINKQUALITY = "LINKQUALITY",
}


export type MeasurementResults = {
  __typename: "MeasurementResults",
  deviceId: string,
  name: string,
  from: number,
  to: number,
  type: MeasurementType,
  data:  Array<Measurement >,
};

export type Measurement = {
  __typename: "Measurement",
  deviceId: string,
  name: string,
  timestamp: number,
  type: MeasurementType,
  value: number,
};

export type GetSwitchDevicesQuery = {
  getDevices:  {
    __typename: "DevicesResponse",
    devices:  Array<( {
        __typename: "MoesMS104ZSwitch",
        deviceId: string,
        elapsed?: number | null,
        last_seen?: number | null,
        linkquality?: number | null,
        name: string,
        device?:  {
          __typename: string,
          applicationVersion?: number | null,
          friendlyName?: string | null,
          hardwareVersion?: number | null,
          ieeeAddr?: string | null,
          manufacturerID?: number | null,
          manufacturerName?: string | null,
          model?: string | null,
          networkAddress?: number | null,
          powerSource?: string | null,
          stackVersion?: number | null,
          type?: string | null,
          zclVersion?: number | null,
        } | null,
        state: MoesMS104ZSwitchState,
        power_on_behavior?: MoesMS104ZSwitchPowerOnBehavior | null,
      } | {
        __typename: "AqaraTempHumiPresSensor",
      }
    ) >,
  },
};

export type GetSensorDevicesQuery = {
  getDevices:  {
    __typename: "DevicesResponse",
    devices:  Array<( {
        __typename: "MoesMS104ZSwitch",
      } | {
        __typename: "AqaraTempHumiPresSensor",
        deviceId: string,
        elapsed?: number | null,
        last_seen?: number | null,
        linkquality?: number | null,
        name: string,
        device?:  {
          __typename: string,
          applicationVersion?: number | null,
          friendlyName?: string | null,
          hardwareVersion?: number | null,
          ieeeAddr?: string | null,
          manufacturerID?: number | null,
          manufacturerName?: string | null,
          model?: string | null,
          networkAddress?: number | null,
          powerSource?: string | null,
          stackVersion?: number | null,
          type?: string | null,
          zclVersion?: number | null,
          dateCode?: string | null,
          softwareBuildID?: string | null,
        } | null,
        battery?: number | null,
        humidity?: number | null,
        pressure?: number | null,
        temperature?: number | null,
        voltage?: number | null,
        power_outage_count?: number | null,
      }
    ) >,
  },
};

export type UpdateSwitchStateMutationVariables = {
  input: UpdateSwitchStateInput,
};

export type UpdateSwitchStateMutation = {
  updateSwitchState?:  {
    __typename: "MoesMS104ZSwitch",
    deviceId: string,
    elapsed?: number | null,
    last_seen?: number | null,
    linkquality?: number | null,
    name: string,
    device?:  {
      __typename: "SwitchDeviceInfo",
      applicationVersion?: number | null,
      friendlyName?: string | null,
      hardwareVersion?: number | null,
      ieeeAddr?: string | null,
      manufacturerID?: number | null,
      manufacturerName?: string | null,
      model?: string | null,
      networkAddress?: number | null,
      powerSource?: string | null,
      stackVersion?: number | null,
      type?: string | null,
      zclVersion?: number | null,
    } | null,
    state: MoesMS104ZSwitchState,
    power_on_behavior?: MoesMS104ZSwitchPowerOnBehavior | null,
  } | null,
};

export type GetDevicesQuery = {
  getDevices:  {
    __typename: "DevicesResponse",
    devices:  Array<( {
        __typename: "MoesMS104ZSwitch",
        deviceId: string,
        elapsed?: number | null,
        last_seen?: number | null,
        linkquality?: number | null,
        name: string,
        device?:  {
          __typename: string,
          applicationVersion?: number | null,
          friendlyName?: string | null,
          hardwareVersion?: number | null,
          ieeeAddr?: string | null,
          manufacturerID?: number | null,
          manufacturerName?: string | null,
          model?: string | null,
          networkAddress?: number | null,
          powerSource?: string | null,
          stackVersion?: number | null,
          type?: string | null,
          zclVersion?: number | null,
        } | null,
        state: MoesMS104ZSwitchState,
        power_on_behavior?: MoesMS104ZSwitchPowerOnBehavior | null,
      } | {
        __typename: "AqaraTempHumiPresSensor",
        deviceId: string,
        elapsed?: number | null,
        last_seen?: number | null,
        linkquality?: number | null,
        name: string,
        device?:  {
          __typename: string,
          applicationVersion?: number | null,
          friendlyName?: string | null,
          hardwareVersion?: number | null,
          ieeeAddr?: string | null,
          manufacturerID?: number | null,
          manufacturerName?: string | null,
          model?: string | null,
          networkAddress?: number | null,
          powerSource?: string | null,
          stackVersion?: number | null,
          type?: string | null,
          zclVersion?: number | null,
          dateCode?: string | null,
          softwareBuildID?: string | null,
        } | null,
        battery?: number | null,
        humidity?: number | null,
        pressure?: number | null,
        temperature?: number | null,
        voltage?: number | null,
        power_outage_count?: number | null,
      }
    ) >,
  },
};

export type GetDeviceStateQueryVariables = {
  deviceId: string,
};

export type GetDeviceStateQuery = {
  getDeviceState: ( {
      __typename: "MoesMS104ZSwitch",
      deviceId: string,
      elapsed?: number | null,
      last_seen?: number | null,
      linkquality?: number | null,
      name: string,
      device?:  {
        __typename: string,
        applicationVersion?: number | null,
        friendlyName?: string | null,
        hardwareVersion?: number | null,
        ieeeAddr?: string | null,
        manufacturerID?: number | null,
        manufacturerName?: string | null,
        model?: string | null,
        networkAddress?: number | null,
        powerSource?: string | null,
        stackVersion?: number | null,
        type?: string | null,
        zclVersion?: number | null,
      } | null,
      state: MoesMS104ZSwitchState,
      power_on_behavior?: MoesMS104ZSwitchPowerOnBehavior | null,
    } | {
      __typename: "AqaraTempHumiPresSensor",
      deviceId: string,
      elapsed?: number | null,
      last_seen?: number | null,
      linkquality?: number | null,
      name: string,
      device?:  {
        __typename: string,
        applicationVersion?: number | null,
        friendlyName?: string | null,
        hardwareVersion?: number | null,
        ieeeAddr?: string | null,
        manufacturerID?: number | null,
        manufacturerName?: string | null,
        model?: string | null,
        networkAddress?: number | null,
        powerSource?: string | null,
        stackVersion?: number | null,
        type?: string | null,
        zclVersion?: number | null,
        dateCode?: string | null,
        softwareBuildID?: string | null,
      } | null,
      battery?: number | null,
      humidity?: number | null,
      pressure?: number | null,
      temperature?: number | null,
      voltage?: number | null,
      power_outage_count?: number | null,
    }
  ),
};

export type GetMeasurementsQueryVariables = {
  deviceId: string,
  type: MeasurementType,
  from: number,
  to: number,
  limit?: number | null,
};

export type GetMeasurementsQuery = {
  getMeasurements:  {
    __typename: "MeasurementResults",
    deviceId: string,
    name: string,
    from: number,
    to: number,
    type: MeasurementType,
    data:  Array< {
      __typename: "Measurement",
      deviceId: string,
      name: string,
      timestamp: number,
      type: MeasurementType,
      value: number,
    } >,
  },
};
