import { Checkbox, FormControl, IconButton, Input, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material'
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import RefreshIcon from '@mui/icons-material/Refresh'
import 'dayjs/locale/fi'
import React, { useEffect, useState } from 'react'
import { VictoryBrushContainer, VictoryChart, VictoryLegend, VictoryLine, VictoryTheme, VictoryZoomContainer } from 'victory'
import { MeasurementType } from '../../api/API'
import { getSensorDevicesQuery } from '../../api/majalahti-api'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { deserializeData, handleRefresh, handleSelectGraph, handleStarTimeChange, handleStopTimeChange, handleTypeChange, handleZoomEvent, queryData, selectDevices, selectFrom, selectSelectedDeviceNames, selectSelectedDevices, selectTo, selectType, selectZoomDomain, serializeZoomDomain, setDevices } from './chart-slice'
import { StateSensor } from './types'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  }
}

const types: MeasurementType[] = [
  MeasurementType.BATTERY,
  MeasurementType.HUMIDITY,
  MeasurementType.LINKQUALITY,
  MeasurementType.PRESSURE,
  MeasurementType.TEMPERATURE,
  MeasurementType.VOLTAGE,
]

interface MeasDataChartProps {
  width: number
  height: number
}

export const MeasDataChart = (props: MeasDataChartProps) => {
  const chartTheme = VictoryTheme.material
  const dispatch = useAppDispatch()
  const from = useAppSelector(selectFrom)
  const to = useAppSelector(selectTo)
  const type = useAppSelector(selectType)
  // Menu states
  const [sensorMenuOpen, setSensorMenuOpen] = useState(false)
  const handleSensorMenuOpen = () => setSensorMenuOpen(true)
  const handleSensorMenuClose = () => setSensorMenuOpen(false)
  const [typeMenuOpen, setTypeMenuOpen] = useState(false)
  const handleTypeMenuOpen = () => setTypeMenuOpen(true)
  const handleTypeMenuClose = () => setTypeMenuOpen(false)
  // Fetch initial data
  useEffect(() => {
    console.log('UseEffect fetching data...')
    const fetchData = async () => {
      const devices = await queryData({
        devices: await getSensorDevicesQuery() as StateSensor[],
        from,
        to,
        type,
      })
      devices.forEach(d => d.selected = false)
      // Select the first device by default
      devices[0].selected = true
      dispatch(setDevices(devices))
    }
    fetchData()
    console.log('UseEffect fetching done!')
  }, [dispatch, from, to, type])

  return (
    <div>
      <FormControl sx={{m: 1, minWidth: 140}}>
        <InputLabel id='sensor-selector-label'>Sensorit</InputLabel>
        <Select
          labelId='sensor-selector-label'
          id='sensor-selector'
          multiple
          value={useAppSelector(selectSelectedDeviceNames)}
          onChange={e => dispatch(handleSelectGraph(e.target.value as string[]))}
          input={
            <Input id='select-sensor'/>
          }
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          variant='outlined'
          open={sensorMenuOpen}
          onOpen={handleSensorMenuOpen}
          onClick={handleSensorMenuClose}
        >
          {
            useAppSelector(selectDevices).map(d => (
              <MenuItem key={d.name} value={d.displayName}>
                <Checkbox checked={d.selected} />
                <ListItemText primary={d.displayName} />
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl sx={{m: 1, minWidth: 140}}>
        <InputLabel id='type-selector-label'>Tyyppi</InputLabel>
        <Select
          labelId='type-selector-label'
          id='type-selector'
          value={useAppSelector(selectType)}
          onChange={e => dispatch(handleTypeChange(e))}
          input={
            <Input id='select-type'/>
          }
          MenuProps={MenuProps}
          variant='outlined'
          open={typeMenuOpen}
          onOpen={handleTypeMenuOpen}
          onClick={handleTypeMenuClose}
        >
          {
            types.map(value => (
              <MenuItem key={value} value={value}>{value}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl sx={{m: 1, minWidth: 140}}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fi'}>
          <DesktopDateTimePicker
            label='Alkuaika'
            value={useAppSelector(selectFrom)}
            onChange={(time) => dispatch(handleStarTimeChange(time!.valueOf()))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl sx={{m: 1, minWidth: 140}}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fi'}>
          <DesktopDateTimePicker
            label='Loppuaika'
            value={useAppSelector(selectTo)}
            onChange={(time) => dispatch(handleStopTimeChange(time!.valueOf()))}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl sx={{m: 1, minWidth: 140}}>
        <IconButton aria-label='refresh' onClick={() => dispatch(handleRefresh())}>
          <RefreshIcon/>
        </IconButton>
      </FormControl>
      <VictoryChart
        theme={chartTheme}
        width={props.width}
        height={Math.min(Math.max(Math.floor(props.width * 0.6), 100), 500)}
        scale={{x: 'time', y: 'linear'}}
        containerComponent={
          <VictoryZoomContainer
            zoomDimension='x'
            zoomDomain={useAppSelector(selectZoomDomain)}
            onZoomDomainChange={e => dispatch(handleZoomEvent(serializeZoomDomain(e)))}
          />
        }
      >
        {
          useAppSelector(selectSelectedDevices).map(d => (
            <VictoryLine
              key={d.name}
              data={deserializeData(d.data!.data)}
              interpolation='catmullRom'
              x='timestamp'
              y='value'
              style={{data: {stroke: d.color!}}}
            />
          ))
        }
        <VictoryLegend
          theme={chartTheme}
          orientation='vertical'
          data={useAppSelector(selectSelectedDevices).map(d => {
            return {name: d.displayName, symbol: {fill: d.color!}}
          })}
        />
      </VictoryChart>
      <VictoryChart
        theme={chartTheme}
        width={props.width}
        height={Math.min(Math.max(Math.floor(props.width * 0.2), 10), 50)}
        scale={{x: 'time', y: 'linear'}}
        padding={{ top: 0, left: 50, right: 50, bottom: 0 }}
        containerComponent={
          <VictoryBrushContainer
            brushDimension='x'
            brushDomain={useAppSelector(selectZoomDomain)}
            onBrushDomainChange={e => dispatch(handleZoomEvent(serializeZoomDomain(e)))}
          />
        }
      >
        {
          useAppSelector(selectSelectedDevices).map(d => (
            <VictoryLine
              key={d.name}
              data={deserializeData(d.data!.data)}
              interpolation='catmullRom'
              x='timestamp'
              y='value'
              style={{data: {stroke: d.color!}}}
            />
          ))
        }
      </VictoryChart>
    </div>
  )
}