import React, { useEffect } from 'react'
import { FormControlLabel, Switch, TextField } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getSwitchDevice, handleToggleSwitch, selectSwitchChecked, selectSwitchDevice, setSwitch, setSwitchChecked } from './heating-control-slice'
import { styled } from '@mui/system'
import { StateSwitch } from './types'

const StyledTextFieldGreen = styled(TextField)({
  color: 'green',
  width: '5ch',
  input: {
    color: 'green',
  },
  label: {
    color: 'green'
  },
})

const StyledTextFieldRed = styled(TextField)({
  color: 'red',
  width: '5ch',
  input: {
    color: 'red',
  },
})

const isOnline = (device?: StateSwitch): boolean => {
  const now = new Date()
  if (!device || !device.last_seen) {
    return false
  }
  return device.last_seen > now.setMinutes(now.getMinutes() - 15)
}

export const HeatingControl = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    console.log('UseEffect fetching switch data...')
    const fetchData = async () => {
      const device = await getSwitchDevice()
      dispatch(setSwitch(device))
      dispatch(setSwitchChecked(device.state))
    }
    fetchData()
  }, [dispatch])
  
  return (
    <div>
      <FormControlLabel
        control={<Switch checked={useAppSelector(selectSwitchChecked)} onChange={(e) => dispatch(handleToggleSwitch(e.target.checked))} />}
        label="Lämpötilan pudotus"
      />
      {isOnline(useAppSelector(selectSwitchDevice)) ? <StyledTextFieldGreen sx={{
        '& .MuiOutlinedInput-root': {
          '& > fieldset': {
            borderColor: 'green'
          },
        },
        }} label='Kytkin' inputProps={{disabled: true}} defaultValue='Online' /> : <StyledTextFieldRed error label='Kytkin' inputProps={{disabled: true}} defaultValue='Offline' />}
    </div>
  )
}