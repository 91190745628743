import React, { useEffect } from 'react'
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { AqaraTempHumiPresSensor } from '../../api/API'
import { getSensorDevicesQuery } from '../../api/majalahti-api'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectDevices, setDevices } from './sensor-list-slice'
import { styled } from '@mui/system'

interface RowData {
  name: string
  temperature?: number | null
  humidity?: number | null
  pressure?: number | null
  battery?: number | null
  linkquality?: number | null
  online?: boolean | null
}

const mapData = (device: AqaraTempHumiPresSensor): RowData => {
  const { name, temperature, humidity, pressure, battery, linkquality } = device
  const now = new Date()
  const online = device.last_seen! > Date.now() - now.setMinutes(now.getMinutes() - 15)
  return {
    name,
    temperature,
    humidity,
    pressure,
    battery,
    linkquality,
    online
  }
}

const StyledCellRed = styled(TableCell)({
  color: 'red'
})

const StyledCellGreen = styled(TableCell)({
  color: 'green'
})

export const SensorList = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const fetchData = async () => {
      console.log('UseEffect fetching data for device states...')
      const devices = await getSensorDevicesQuery()
      dispatch(setDevices(devices))
    }
    fetchData()
  }, [dispatch])

  return (
    <TableContainer component={Paper}>
      <Table aria-label='sensor-table'>
        <TableHead>
          <TableRow>
            <TableCell align='right'>Sensori</TableCell>
            <TableCell align='right'>Lämpötila</TableCell>
            <TableCell align='right'>Kosteus</TableCell>
            <TableCell align='right'>Ilmanpaine</TableCell>
            <TableCell align='right'>Patteri</TableCell>
            <TableCell align='right'>Linkki</TableCell>
            <TableCell align='right'>Online?</TableCell>
          </TableRow>
          {useAppSelector(selectDevices).map(d => mapData(d)).filter(d => d.name).map(row => (
            <TableRow key={row.name}>
              <TableCell align='right' component='th' scope='row'>{row.name}</TableCell>
              <TableCell align='right'>{row.temperature}</TableCell>
              <TableCell align='right'>{row.humidity}</TableCell>
              <TableCell align='right'>{row.pressure}</TableCell>
              <TableCell align='right'>{row.battery}</TableCell>
              <TableCell align='right'>{row.linkquality}</TableCell>
              {row.online ? <StyledCellGreen align='right'>Online</StyledCellGreen> : <StyledCellRed align='right'>Offline</StyledCellRed>}
            </TableRow>
          ))}
        </TableHead>
      </Table>
    </TableContainer>
  )
}