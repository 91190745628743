import React, { useEffect, useState } from 'react'
import { withAuthenticator, WithAuthenticatorProps} from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'
import awsExports from './aws-exports'
import './App.css'
import { MeasDataChart } from './features/chart/chart'
import { AppBar, Button, Card, CardActions, CardContent, Toolbar, Typography } from '@mui/material'
import { HeatingControl } from './features/heating-control/heating-control'
import { SensorList } from './features/sensor-list/sensor-list'
import { Box } from '@mui/system'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

Amplify.configure(awsExports)

const App = ({ signOut, user }: WithAuthenticatorProps) => {
  const username = user ? user.getUsername() : ''
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight)
  })
  return (
    <div className="App">
      <header className="App-header">
        <AppBar sx={{marginBottom: 5}} position='static'>
          <Toolbar sx={{spacing: 3}}>
            <Typography variant='h3'>MAJALAHTI-UI</Typography>
            <Typography sx={{marginLeft: 'auto'}} variant='h5'> Tervetuloa {username.toUpperCase()}</Typography>
            <Button sx={{marginLeft: 3}} color='inherit' onClick={signOut}>Kirjaudu ulos</Button>
          </Toolbar>
        </AppBar>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <Box alignContent='center'>
          <Grid2 container direction='column' spacing={5} justifyContent='center' alignContent='center'>
            <Grid2 xs={12}>
              <Card sx={{width: Math.floor(width * 0.8)}}>
                <CardActions>
                  <HeatingControl />
                </CardActions>
              </Card>
            </Grid2>
            <Grid2 xs={12}>
            <Card sx={{width: Math.floor(width * 0.8)}}>
              <CardContent>
                <SensorList />
              </CardContent>
            </Card>
            </Grid2>
            <Grid2 xs={12}>
              <Card sx={{width: Math.floor(width * 0.8)}}>
                <CardContent>
                  <MeasDataChart width={Math.floor(width * 0.8)} height={Math.floor(height * 0.8)}/>
                </CardContent>
              </Card>
            </Grid2>
          </Grid2>
        </Box>
      </header>
    </div>
  )
}

export default withAuthenticator(App, {
  hideSignUp: true
})
