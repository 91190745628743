/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDevices = /* GraphQL */ `
  query GetDevices {
    getDevices {
      devices {
        ... on MoesMS104ZSwitch {
          deviceId
          elapsed
          last_seen
          linkquality
          name
          device {
            applicationVersion
            friendlyName
            hardwareVersion
            ieeeAddr
            manufacturerID
            manufacturerName
            model
            networkAddress
            powerSource
            stackVersion
            type
            zclVersion
          }
          state
          power_on_behavior
        }
        ... on AqaraTempHumiPresSensor {
          deviceId
          elapsed
          last_seen
          linkquality
          name
          device {
            applicationVersion
            friendlyName
            hardwareVersion
            ieeeAddr
            manufacturerID
            manufacturerName
            model
            networkAddress
            powerSource
            stackVersion
            type
            zclVersion
            dateCode
            softwareBuildID
          }
          battery
          humidity
          pressure
          temperature
          voltage
          power_outage_count
        }
      }
    }
  }
`;
export const getDeviceState = /* GraphQL */ `
  query GetDeviceState($deviceId: ID!) {
    getDeviceState(deviceId: $deviceId) {
      ... on MoesMS104ZSwitch {
        deviceId
        elapsed
        last_seen
        linkquality
        name
        device {
          applicationVersion
          friendlyName
          hardwareVersion
          ieeeAddr
          manufacturerID
          manufacturerName
          model
          networkAddress
          powerSource
          stackVersion
          type
          zclVersion
        }
        state
        power_on_behavior
      }
      ... on AqaraTempHumiPresSensor {
        deviceId
        elapsed
        last_seen
        linkquality
        name
        device {
          applicationVersion
          friendlyName
          hardwareVersion
          ieeeAddr
          manufacturerID
          manufacturerName
          model
          networkAddress
          powerSource
          stackVersion
          type
          zclVersion
          dateCode
          softwareBuildID
        }
        battery
        humidity
        pressure
        temperature
        voltage
        power_outage_count
      }
    }
  }
`;
export const getMeasurements = /* GraphQL */ `
  query GetMeasurements(
    $deviceId: ID!
    $type: MeasurementType!
    $from: AWSTimestamp!
    $to: AWSTimestamp!
    $limit: Int
  ) {
    getMeasurements(
      deviceId: $deviceId
      type: $type
      from: $from
      to: $to
      limit: $limit
    ) {
      deviceId
      name
      from
      to
      type
      data {
        deviceId
        name
        timestamp
        type
        value
      }
    }
  }
`;
