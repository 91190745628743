import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MoesMS104ZSwitchState } from '../../api/API'
import { getDeviceStateQuery, getSwitchDevicesQuery, updateSwitchStateMutation } from '../../api/majalahti-api'
import { RootState } from '../../app/store'
import { HeatingControlState, StateSwitch, Status } from './types'

export const getSwitchDevice = async (): Promise<StateSwitch> => {
  const device = await getSwitchDevicesQuery()
    .then(res => res[0] as StateSwitch)
  return device
}

export const refreshSwitchDevice = async (deviceId: string): Promise<StateSwitch> => {
  const device = await getDeviceStateQuery(deviceId) as StateSwitch
  return device
}

export const toggleSwitch = async (device: StateSwitch, switchChecked: boolean): Promise<StateSwitch> => {
  const switchState = switchChecked ? MoesMS104ZSwitchState.ON : MoesMS104ZSwitchState.OFF
  await updateSwitchStateMutation({name: device.name, state: switchState})
  // Wait for a while before refreshing switch state
  // await new Promise((resolve) => setTimeout(() => resolve(true), 2000))
  const updatedDevice = await refreshSwitchDevice(device.deviceId)
  return updatedDevice
}

export const handleToggleSwitch = createAsyncThunk(
  'heatingControl/handleToggleSwitch',
  async (checked: boolean, {getState}) => {
    const state = getState() as RootState
    if(!state.heatingControl.device) {
      throw new Error('Device is not defined!')
    }
    const device = await toggleSwitch(state.heatingControl.device, checked)
    return {device, checked}
  }
)

const initialState: HeatingControlState = {
  device: undefined,
  switchChecked: false,
  status: Status.IDLE,
}

export const mapSwitchState = (state: MoesMS104ZSwitchState): boolean => {
  if(state === MoesMS104ZSwitchState.ON) {
    return true
  }
  if(state === MoesMS104ZSwitchState.OFF) {
    return false
  }
  throw new Error('Toggle state is not supported!')
}

export const heatingControlSlice = createSlice({
  name: 'heatingControl',
  initialState,
  reducers: {
    setSwitch: (state, action: PayloadAction<StateSwitch>) => {
      state.device = action.payload
    },
    setSwitchChecked: (state, action: PayloadAction<MoesMS104ZSwitchState>) => {
      state.switchChecked = mapSwitchState(action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleToggleSwitch.pending, (state) => {
        state.status = Status.PENDING
      })
      .addCase(handleToggleSwitch.fulfilled, (state, action) => {
        state.switchChecked = action.payload.checked
        state.device = action.payload.device
        state.status = Status.IDLE
      })
      .addCase(handleToggleSwitch.rejected, (state) => {
        state.status = Status.FAILED
      })
  }
})

export const { setSwitch, setSwitchChecked } = heatingControlSlice.actions

export const selectSwitchChecked = (state: RootState) => state.heatingControl.switchChecked
export const selectSwitchDevice = (state: RootState) => state.heatingControl.device

export default heatingControlSlice.reducer