import { API, graphqlOperation } from 'aws-amplify'
import { getSensorDevices, getSwitchDevices } from '../graphql/custom-queries'
import { updateSwitchState } from '../graphql/mutations'
import { getDevices, getDeviceState, getMeasurements } from '../graphql/queries'
import { AqaraTempHumiPresSensor, DevicesResponse, DeviceState, GetDevicesQuery, GetDeviceStateQuery, GetMeasurementsQuery, Measurement, MeasurementType, UpdateSwitchStateInput } from './API'

export const getDeviceStateQuery = async (deviceId: string): Promise<DeviceState> => {
  try {
    const result = await API.graphql(graphqlOperation(getDeviceState, {deviceId})) as { data: GetDeviceStateQuery }
    console.log(JSON.stringify(result, null, 2))
    const state = result.data.getDeviceState as DeviceState
    return state
  }
  catch(err) {
    console.log(`Error fetching device state for ${deviceId}\n${JSON.stringify(err, null, 2)}`)
    throw err
  }
}

export const getDevicesQuery = async (): Promise<DeviceState[]> => {
  try {
    console.log('Fetching device data...')
    const result = await API.graphql(graphqlOperation(getDevices)) as { data: GetDevicesQuery }
    console.log(JSON.stringify(result, null, 2))
    const data = result.data.getDevices as DevicesResponse
    return data.devices.filter(d => d && d.deviceId)
  }
  catch(err) {
    console.log(`Error fetching device states\n${JSON.stringify(err, null, 2)}`)
    throw err
  }
}

export const getSwitchDevicesQuery = async (): Promise<DeviceState[]> => {
  try {
    const result = await API.graphql(graphqlOperation(getSwitchDevices)) as { data: GetDevicesQuery }
    console.log(JSON.stringify(result, null, 2))
    const data = result.data.getDevices as DevicesResponse
    return data.devices
  }
  catch(err) {
    console.log(`Error fetching device states\n${JSON.stringify(err, null, 2)}`)
    throw err
  }
}

export const getSensorDevicesQuery = async (): Promise<AqaraTempHumiPresSensor[]> => {
  try {
    const result = await API.graphql(graphqlOperation(getSensorDevices)) as { data: GetDevicesQuery }
    console.log(JSON.stringify(result, null, 2))
    const data = result.data.getDevices as DevicesResponse
    return data.devices as AqaraTempHumiPresSensor[]
  }
  catch(err) {
    console.log(`Error fetching device states\n${JSON.stringify(err, null, 2)}`)
    throw err
  }
}

export interface GetMeasurementsQueryInput {
  deviceId: string
  from: number
  to: number
  type: MeasurementType
  limit?: number
}

export const getMeasurementsQuery = async (input: GetMeasurementsQueryInput): Promise<Measurement[]> => {
  try {
    console.log('Fetching measurement data')
    console.log(JSON.stringify(input, null, 2))
    const result = await API.graphql(graphqlOperation(getMeasurements, input)) as { data: GetMeasurementsQuery }
    console.log(JSON.stringify(result, null, 2))
    const {data} = result.data.getMeasurements
    return data
  }
  catch(err) {
    console.log(`Error fetching measurement results for ${input.deviceId}\n${JSON.stringify(err, null, 2)}`)
    throw err
  }
}

export const updateSwitchStateMutation = async (input: UpdateSwitchStateInput): Promise<void> => {
  try {
    console.log(`Setting switch state: ${JSON.stringify(input, null, 2)}`)
    await API.graphql(graphqlOperation(updateSwitchState, {input}))
    console.log(`Switch ${input.name} state set to: ${input.state}`)
  }
  catch(err) {
    console.log(`Error updating switch state\n${JSON.stringify(err, null, 2)}`)
    throw err
  }
}
