export const getSwitchDevices = /* GraphQL */ `
  query GetSwitchDevices {
    getDevices {
      devices {
        ... on MoesMS104ZSwitch {
          deviceId
          elapsed
          last_seen
          linkquality
          name
          device {
            applicationVersion
            friendlyName
            hardwareVersion
            ieeeAddr
            manufacturerID
            manufacturerName
            model
            networkAddress
            powerSource
            stackVersion
            type
            zclVersion
          }
          state
          power_on_behavior
        }
      }
    }
  }
`

export const getSensorDevices = /* GraphQL */ `
  query GetSensorDevices {
    getDevices {
      devices {
        ... on AqaraTempHumiPresSensor {
          deviceId
          elapsed
          last_seen
          linkquality
          name
          device {
            applicationVersion
            friendlyName
            hardwareVersion
            ieeeAddr
            manufacturerID
            manufacturerName
            model
            networkAddress
            powerSource
            stackVersion
            type
            zclVersion
            dateCode
            softwareBuildID
          }
          battery
          humidity
          pressure
          temperature
          voltage
          power_outage_count
        }
      }
    }
  }
`
