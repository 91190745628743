import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AqaraTempHumiPresSensor } from '../../api/API'
import { getSensorDevicesQuery } from '../../api/majalahti-api'
import { RootState } from '../../app/store'
import { SensorListState, Status } from './types'

const initialState: SensorListState = {
  devices: [],
  status: Status.IDLE,
}

export const handleRefreshDevices = createAsyncThunk(
  'sensorList/handleRefreshDevices',
  async () => {
    const devices = await getSensorDevicesQuery()
    return {devices}
  }
)

export const sensorListSlice = createSlice({
  name: 'sensorList',
  initialState,
  reducers: {
    setDevices: (state, action: PayloadAction<AqaraTempHumiPresSensor[]>) => {
      state.devices = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleRefreshDevices.pending, (state) => {
        state.status = Status.PENDING
      })
      .addCase(handleRefreshDevices.fulfilled, (state, action) => {
        state.devices = action.payload.devices
        state.status = Status.IDLE
      })
      .addCase(handleRefreshDevices.rejected, (state) => {
        console.error('Device state refresh failed')
        state.status = Status.FAILED
      })
  },
})

export const { setDevices } = sensorListSlice.actions

export const selectDevices = (state: RootState) => state.sensorList.devices

export default sensorListSlice.reducer