/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateSwitchState = /* GraphQL */ `
  mutation UpdateSwitchState($input: UpdateSwitchStateInput!) {
    updateSwitchState(input: $input) {
      deviceId
      elapsed
      last_seen
      linkquality
      name
      device {
        applicationVersion
        friendlyName
        hardwareVersion
        ieeeAddr
        manufacturerID
        manufacturerName
        model
        networkAddress
        powerSource
        stackVersion
        type
        zclVersion
      }
      state
      power_on_behavior
    }
  }
`;
