import { SelectChangeEvent } from '@mui/material'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DomainTuple, Tuple } from 'victory'
import { MeasurementType } from '../../api/API'
import { getMeasurementsQuery, getSensorDevicesQuery } from '../../api/majalahti-api'
import { RootState } from '../../app/store'
import { ChartState, ChartStatus, DataItem, QueryDataInput, SetLabelAction, SetZoomAction, StateDataItem, StateSensor, StateZoomDomain, ZoomDomain } from './types'

const colors = [
  'red',
  'orange',
  'limegreen',
  'blue',
  'purple',
  'magenta',
  'blueviolet',
  'tomato',
  'slategrey',
  'sienna',
]

export const mapSensorName = (name: string): string => {
  switch(name) {
    case 'sensor/kitchen': {
      console.log(`Mapping ${name} to Keittiö`)
      return 'Keittiö'
    }
    case 'Keittiö': {
      console.log(`Mapping ${name} to sensor/kitchen`)
      return 'sensor/kitchen'
    }
    case 'sensor/livingroom': {
      console.log(`Mapping ${name} to Olohuone`)
      return 'Olohuone'
    }
    case 'Olohuone': {
      console.log(`Mapping ${name} to sensor/kitchen`)
      return 'sensor/livingroom'
    }
    case 'sensor/terrace': {
      console.log(`Mapping ${name} to Terassi`)
      return 'Terassi'
    }
    case 'Terassi': {
      console.log(`Mapping ${name} to sensor/terrace`)
      return 'sensor/terrace'
    }
    case 'sensor/bathroom': {
      console.log(`Mapping ${name} to Kylpyhuone`)
      return 'Kylpyhuone'
    }
    case 'Kylpyhuone': {
      console.log(`Mapping ${name} to sensor/bathroom`)
      return 'sensor/bathroom'
    }
    default: {
      return 'Unknown sensor name!'
    }
  }
}

const mapYAxisLabel = (type: MeasurementType): string => {
  switch(type) {
    case MeasurementType.BATTERY: {
      return 'Patteri'
    }
    case MeasurementType.HUMIDITY: {
      return 'Kosteus'
    }
    case MeasurementType.LINKQUALITY: {
      return 'Yhteyden laatu'
    }
    case MeasurementType.PRESSURE: {
      return 'Ilmanpaine'
    }
    case MeasurementType.TEMPERATURE: {
      return 'Lämpötila'
    }
    case MeasurementType.VOLTAGE: {
      return 'Patterijännite'
    }
    default: {
      return 'Unknown measurement label!'
    }
  }
}

const srd = (input: Date | number | string): number => {
  if(!input) {
    throw new Error(`Invalid time format ${input}`)
  }
  if(typeof input === 'string') {
    console.log(`Input is string ${input}`)
    return Date.parse(input)
  }
  if(typeof input === 'object') {
    console.log(`Input is Date ${input}}`)
    const d = input as Date
    return d.getTime()
  }
  console.log(`Input not changed ${input}`)
  return input
}

const dsrd = (input: number[]): DomainTuple => {
  if(input[0] > 1e12) {  // Input is likely a serialized date object
    return [new Date(input[0]), new Date(input[1])]
  }
  return input as Tuple<number>
}

export const serializeZoomDomain = (input: ZoomDomain): StateZoomDomain => {
  console.log(JSON.stringify(input, null, 2))
  const serialized = {
    x: [srd(input.x[0]), srd(input.x[1])],
    y: input.y ? [srd(input.y[0]), srd(input.y[1])] : undefined,
  }
  console.log(JSON.stringify(serialized, null, 2))
  return serialized
}

const deserializeZoomDomain = (input: StateZoomDomain): ZoomDomain => {
  return {
    x: dsrd(input.x),
    y: input.y ? dsrd(input.y) : undefined,
  }
}

export const serializeData = (input: DataItem[]): StateDataItem[] => input.map(i => <StateDataItem> {timestamp: i.timestamp.getTime(), value: i.value})

export const deserializeData = (input: StateDataItem[]): DataItem[] => input.map(i => <DataItem> {timestamp: new Date(i.timestamp), value: i.value})

const getZoomDomain = (from?: number, to?: number): StateZoomDomain => {
  if(!from && !to) {
    const now = new Date()
    const start = new Date()
    start.setHours(start.getHours() - 72)
    return {x: [start.getTime(), now.getTime()]}
  }
  if(from && !to) {
    return {x: [from, Date.now()]}
  }
  if(from && to) {
    if(from >= to) {
      throw new Error('Start time cannot be later than end time')
    }
    return {x: [from, to]}
  }
  throw new Error('Unexpected error defining chart state')
}

const fixTimezoneOffset = (ts: number): number => {
  const d = new Date(ts)
  return d.setMinutes(d.getMinutes() - d.getTimezoneOffset())
}

export const queryData = async (input: QueryDataInput): Promise<StateSensor[]> => {
  // Clone the device list so this can be used within state update too
  const devices = JSON.parse(JSON.stringify(input.devices.filter(d => d && d.deviceId))) as StateSensor[]
  await Promise.all(devices.map(d => getMeasurementsQuery({
    deviceId: d.deviceId,
    from: input.from,
    to: input.to,
    type: input.type,
    limit: input.limit
  })
    .then(data => d.data = {
      from: input.from,
      to: input.to,
      type: input.type,
      data: data.map(i => <StateDataItem> {timestamp: fixTimezoneOffset(i.timestamp), value: i.value})
    })
  ))
  for(const d of devices) {
    d.displayName = mapSensorName(d.name)
  }
  // Sort by display name
  devices.sort((a, b) => {
    if(!a.displayName) {
      return 0
    }
    if(!b.displayName) {
      return 0
    }
    if(a.displayName > b.displayName) {
      return 1
    }
    if(b.displayName > a.displayName) {
      return -1
    }
    return 0
  })
  for(const d of devices) {
    d.color = colors[devices.indexOf(d)]
  }
  return devices
}

export const fetchChartData = createAsyncThunk(
  'chart/fetchChartData',
  async (input, { getState }) => {
    const state = getState() as ChartState
    console.log('Fetching data from backend, initial state:')
    console.log(JSON.stringify(state, null, 2))

    let devices = await getSensorDevicesQuery()
    devices = await queryData({
      devices,
      from: state.from,
      to: state.to,
      type: state.type
    })

    console.log(JSON.stringify(devices, null, 2))

    return devices
  }
)

export const handleTypeChange = createAsyncThunk(
  'chart/handleTypeChange',
  async (event: SelectChangeEvent<MeasurementType>, {getState}) => {
    const type = event.target.value as MeasurementType
    console.log(`Type changed to: ${type}`)
    const state = getState() as RootState

    const devices = await queryData({
      devices: state.chart.devices,
      from: state.chart.from,
      to: state.chart.to,
      type,
    })
      .then(res => res.filter(d => d && d.deviceId))
      .catch(err => {
        console.error(err)
        throw err
      })
    
    for(const d of devices) {
      const old = state.chart.devices.find(i => i.deviceId === d.deviceId)
      if(old) {
        d.selected = old.selected
      }
    }

    console.log(JSON.stringify(devices.map(d => {
      return {
        deviceId: d.deviceId,
        name: d.name,
        selected: d.selected,
      }
    }), null, 2))

    return {
      type,
      devices,
    }
  }
)

export const handleStarTimeChange = createAsyncThunk(
  'chart/handleStartTimeChange',
  async (time: number | null, {getState}) => {
    console.log(`Set start time to ${time}`)
    const state = getState() as RootState
    if(!time) {
      console.log('No change!')
      return {from: state.chart.from, devices: state.chart.devices}
    }
    let from = time
    if(from >= Date.now()) {
      const now = new Date()
      from = now.setHours(now.getHours() - 3)
    }
    if(from >= state.chart.to) {
      const d = new Date(state.chart.to)
      from = d.setHours(d.getHours() - 3)
    }

    console.log(JSON.stringify({
      from,
      to: state.chart.to,
      type: state.chart.type,
    }))

    const devices = await queryData({
      devices: state.chart.devices,
      from,
      to: state.chart.to,
      type: state.chart.type,
    })
      .then(res => res.filter(d => d && d.deviceId))
      .catch(err => {
        console.error(err)
        throw err
      })
    
    for(const d of devices) {
      const old = state.chart.devices.find(i => i.deviceId === d.deviceId)
      if(old) {
        d.selected = old.selected
      }
    }
    return {from, devices}
  }
)

export const handleStopTimeChange = createAsyncThunk(
  'chart/handleStopTimeChange',
  async (time: number | null, {getState}) => {
    console.log(`Set stop time to ${time}`)
    const state = getState() as RootState
    if(!time) {
      return {to: state.chart.to, devices: state.chart.devices}
    }
    let to = time
    if(to >= Date.now()) {
      to = Date.now()
    }
    if(to <= state.chart.from) {
      const d = new Date(state.chart.from)
      to = d.setHours(d.getHours() + 3)
    }

    const devices = await queryData({
      devices: state.chart.devices,
      from: state.chart.from,
      to,
      type: state.chart.type,
    })
      .then(res => res.filter(d => d && d.deviceId))
      .catch(err => {
        console.error(err)
        throw err
      })
    
    for(const d of devices) {
      const old = state.chart.devices.find(i => i.deviceId === d.deviceId)
      if(old) {
        d.selected = old.selected
      }
    }
    return {to, devices}
  }
)

export const handleRefresh = createAsyncThunk(
  'chart/handleRefresh',
  async (input, {getState}) => {
    const state = getState() as RootState
    const {devices, from, to, type} = state.chart

    const newDevices = await queryData({
      devices,
      from: Date.now() - (to - from),
      to: Date.now(),
      type,
    })

    for(const d of devices) {
      const old = state.chart.devices.find(i => i.deviceId === d.deviceId)
      if(old) {
        d.selected = old.selected
      }
    }

    const zoomDomain = getZoomDomain(from, to)

    return {devices: newDevices, from, to, zoomDomain}
  }
)

// const mapDeviceId = (devices: StateSensor[], name: string): string | undefined => {
//   if(name.startsWith('0x')) {
//     return name
//   }
//   const d1 = devices.find(d => d.name && d.name === name)
//   if(d1) {
//     return d1.deviceId
//   }
//   const d2 = devices.find(d => d.displayName && d.displayName === name)
//   if(d2) {
//     return d2.deviceId
//   }
//   return undefined
// }

const initialState: ChartState = {
  devices: [],
  title: '',
  type: MeasurementType.TEMPERATURE,
  xLabel: '',
  yLabel: mapYAxisLabel(MeasurementType.TEMPERATURE),
  zoomDomain: getZoomDomain(),
  status: ChartStatus.IDLE,
  from: getZoomDomain().x[0],
  to: getZoomDomain().x[1],
}

export const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    handleSelectGraph: (state, action: PayloadAction<string[]>) => {
      const value = action.payload
      console.log(`Clicked ${JSON.stringify(value)}}`)
      for(const d of state.devices) {
        if(value.includes(d.displayName!)) {
          d.selected = true
        }
        else {
          d.selected = false
        }
      }
      console.log(JSON.stringify(state.devices.map(d => {return {id: d.deviceId, selected: d.selected}}), null, 2))
    },
    handleZoomEvent: (state, action: PayloadAction<StateZoomDomain>) => {
      state.zoomDomain = action.payload
    },
    setZoomDomain: (state, action: PayloadAction<SetZoomAction>) => {
      state.zoomDomain = getZoomDomain(action.payload.from, action.payload.to)
    },
    setChartTitle: (state, action: PayloadAction<SetLabelAction>) => {
      state.title = mapSensorName(action.payload.label)
    },
    setDevices: (state, action: PayloadAction<StateSensor[]>) => {
      const devices = action.payload.filter(d => d && d.deviceId)
      console.log('Set devices to')
      console.log(JSON.stringify(devices, null, 2))
      state.devices = devices
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChartData.pending, (state) => {
        state.status = ChartStatus.LOADING
      })
      .addCase(fetchChartData.fulfilled, (state, action) => {
        state.status = ChartStatus.IDLE
        state.devices = action.payload
      })
      .addCase(fetchChartData.rejected, (state) => {
        state.status = ChartStatus.FAILED
        console.error('Data fetch failed!')
      })
      .addCase(handleTypeChange.pending, (state) => {
        state.status = ChartStatus.LOADING
      })
      .addCase(handleTypeChange.fulfilled, (state, action) => {
        state.status = ChartStatus.IDLE
        state.type = action.payload.type
        state.yLabel = mapYAxisLabel(action.payload.type)
      })
      .addCase(handleTypeChange.rejected, (state) => {
        console.error('Type update failed!')
        state.status = ChartStatus.FAILED
      })
      .addCase(handleStarTimeChange.pending, (state) =>{
        state.status = ChartStatus.LOADING
      })
      .addCase(handleStarTimeChange.fulfilled, (state, action) =>{
        state.status = ChartStatus.IDLE
        state.from = action.payload.from!
        state.devices = action.payload.devices
      })
      .addCase(handleStarTimeChange.rejected, (state) => {
        state.status = ChartStatus.FAILED
        console.error('Start time update failed!')
      })
      .addCase(handleStopTimeChange.pending, (state) =>{
        state.status = ChartStatus.LOADING
      })
      .addCase(handleStopTimeChange.fulfilled, (state, action) =>{
        state.status = ChartStatus.IDLE
        state.to = action.payload.to!
        state.devices = action.payload.devices
      })
      .addCase(handleStopTimeChange.rejected, (state) => {
        state.status = ChartStatus.FAILED
        console.error('Stop time update failed!')
      })
      .addCase(handleRefresh.pending, (state) => {
        state.status = ChartStatus.LOADING
      })
      .addCase(handleRefresh.fulfilled, (state, action) => {
        state.status = ChartStatus.IDLE
        state.devices = action.payload.devices
        state.from = action.payload.from
        state.to = action.payload.to
        state.zoomDomain = action.payload.zoomDomain
      })
      .addCase(handleRefresh.rejected, (state) => {
        state.status = ChartStatus.FAILED
      })
  }
})

export const { handleSelectGraph: handleSelectGraph, handleZoomEvent, setZoomDomain, setChartTitle, setDevices } = chartSlice.actions

export const selectType = (state: RootState) => state.chart.type
export const selectDevices = (state: RootState) => state.chart.devices
export const selectTitle = (state: RootState) => state.chart.title
export const selectXLabel = (state: RootState) => state.chart.xLabel
export const selectYLabel = (state: RootState) => state.chart.yLabel
export const selectZoomDomain = (state: RootState) => deserializeZoomDomain(state.chart.zoomDomain)
export const selectStatus = (state: RootState) => state.chart.status
export const selectDeviceNames = (state: RootState) => state.chart.devices.map(d => d.name)
export const selectSelectedDevices = (state: RootState) => state.chart.devices.filter(d => d.selected)
export const selectSelectedDeviceNames = (state: RootState) => state.chart.devices.filter(d => d.selected).map(d => d.displayName).sort()
export const selectFrom = (state: RootState) => state.chart.from
export const selectTo = (state: RootState) => state.chart.to

export default chartSlice.reducer
