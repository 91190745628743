import { MoesMS104ZSwitch } from '../../api/API'

export interface StateSwitch extends MoesMS104ZSwitch {
  prop?: string
}

export enum Status {
  IDLE = 'idle',
  PENDING = 'pending',
  FAILED = 'failed'
}

export interface HeatingControlState {
  device?: StateSwitch,
  switchChecked: boolean
  status: Status
}

