import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import chartReducer from '../features/chart/chart-slice'
import heatingControlReducer from '../features/heating-control/heating-control-slice'
import sensorListReducer from '../features/sensor-list/sensor-list-slice'

export const store = configureStore({
  reducer: {
    chart: chartReducer,
    heatingControl: heatingControlReducer,
    sensorList: sensorListReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
