import { SelectChangeEvent } from '@mui/material'
import { DomainTuple } from 'victory'
import { AqaraTempHumiPresSensor, MeasurementType } from '../../api/API'

export enum ChartStatus {
  LOADING = 'loading',
  IDLE = 'idle',
  FAILED = 'failed',
}

export interface QueryDataInput {
  devices: StateSensor[]
  from: number
  to: number
  type: MeasurementType
  limit?: number
}

export interface SetZoomAction {
  from?: number
  to?: number
}

export interface SetLabelAction {
  label: string
}

export interface StateZoomDomain {
  x: number[]
  y?: number[]
}

export interface ZoomDomain {
  x: DomainTuple
  y?: DomainTuple
}

export interface DataItem {
  timestamp: Date
  value: number
}

export interface StateDataItem {
  timestamp: number
  value: number
}

export interface StateData {
  from: number
  to: number
  type: MeasurementType
  data: StateDataItem[]
}

export interface StateSensor extends AqaraTempHumiPresSensor {
  selected?: boolean
  data?: StateData
  displayName?: string
  color?: string
}

export interface ChartState {
  devices: StateSensor[]
  title: string
  type: MeasurementType
  xLabel: string
  yLabel: string
  zoomDomain: StateZoomDomain
  status: ChartStatus
  from: number
  to: number
}

export interface HandleTypeChangeInput {
  event: SelectChangeEvent<string>
  type: MeasurementType
}

export interface LineState {
  data: DataItem[]
  color: string
}